import React from "react";
import Layout from "../components/ui/Layout";
import ButtonLink from "../components/ui/ButtonLink";

const ThankYou = () => {
  return (
    <Layout title="Thanks for your message">
      <section className="section-container">
        <div>
        <h1>Thanks</h1>
        <p>Your message has been sent. I'll get back to you as soon as possible.</p>
        <ButtonLink to="/">Return to home</ButtonLink>
        </div>
      </section>
    </Layout>
  );
};

export default ThankYou;
